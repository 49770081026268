
.data-list-item {
    line-height: 1.5;
    &:not(:first-child) {
        margin-top: 6px !important;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .data-list-item {
        &:not(:first-child) {
            margin-top: 12px !important;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
